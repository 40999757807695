import { TagDescription, Keyword } from '@novaheal/types';

export enum ModalVariant {
  LOGOUT = 'logout',
  DISLIKE = 'dislike',
  TAG = 'tag',
  KEYWORD = 'keyword',
  IMAGE = 'image',
  STOP_EDITING = 'stopEditing',
  SUBSCRIBE = 'subscribe',
  PAYWALL = 'paywall',
  VOUCHER = 'voucher',
}
export type ModalProps =
  | ModalLogoutVariantProps
  | ModalDislikeVariantProps
  | ModalTagVariantProps
  | ModalKeywordVariantProps
  | ModalImageVariantProps
  | ModalStopEditingVariantProps
  | ModalSubscribeVariantProps
  | ModalPaywallVariantProps
  | ModalVoucherProps;

export type ModalContextValueProps = ModalProps & SharedProps;

export type ModalContextProps = {
  currentModalValues: ModalContextValueProps;
  setCurrentModalValues: (value: ModalContextValueProps) => void;
  defaultModalValues: ModalContextValueProps;
};

export enum ModalTarget {
  APP = 'modal-target-app',
  PAGE_MOBILE = 'modal-target-page--mobile',
  PAGE_DESKTOP = 'modal-target-page--desktop',
}

export type SharedProps = {
  isShown: boolean;
};

// Modal Variants
export type ModalLogoutVariantProps = {
  variant: ModalVariant.LOGOUT;
};

export type ModalTagVariantProps = {
  variant: ModalVariant.TAG;
  title?: string;
  tagDescription?: TagDescription[];
};

export type ModalDislikeVariantProps = {
  variant: ModalVariant.DISLIKE;
  onAccept?: () => void;
  name?: string;
};

export type ModalStopEditingVariantProps = {
  variant: ModalVariant.STOP_EDITING;
  onStopEditing?: () => void;
};

export type ModalKeywordVariantProps = {
  variant: ModalVariant.KEYWORD;
  data?: Keyword;
};

export type ModalImageVariantProps = {
  variant: ModalVariant.IMAGE;
  imgUrl?: string;
  title?: string;
  description?: string;
};

export type ModalSubscribeVariantProps = {
  variant: ModalVariant.SUBSCRIBE;
};

export type ModalPaywallVariantProps = {
  variant: ModalVariant.PAYWALL;
  target?: ModalTarget;
  onUpgradeClick?: () => void;
};

export type ModalVoucherProps = {
  variant: ModalVariant.VOUCHER;
};

// Shared Modal Variant Props
export type SharedModalProps = {
  isShown: boolean;
  handleClose: () => void;
};

// Dynamic options helper for Modal Hook

// get current ModalProps type based on passed variant => This is our variant variable we pass to a function
export type ModalPropsVariant = ModalProps['variant'];

// Helper type to filter out specific Key value pairs. In this case only "variant" because we add isShown to Context type later.
// Testcase: type Test = ExcludeNotOptionFields<{ variant: ModalVariant.DISLIKE; isShown: true }>; // Expected {}
type ExcludeNotOptionFields<O> = { [K in Exclude<keyof O, 'variant'>]: O[K] };

// Helper type to check if type is an empty object and return never if it is
// Testcase: type Test = IsEmptyObject<{}>; // Expected never
type IsEmptyObject<O> = keyof O extends never ? never : O;

// Extract Modal option props based on variant & looks like e.g.: { onAccept: () => void }. This is our options variable we pass to a function.
// It returns never if the object is empty
export type ExtractModalOptionsParameters<O, T> = O extends { variant: T }
  ? IsEmptyObject<ExcludeNotOptionFields<O>>
  : never;
