import { ReactElement, useEffect } from 'react';
import { LogoutModal } from 'components/common/Modal/variants/LogoutModal';
import { DislikeModal } from 'components/common/Modal/variants/DislikeModal';
import { useModalService } from 'hooks/useModalService';
import { useModalContext } from 'hooks/utils/useModalContext';
import { ModalContextValueProps, ModalVariant } from 'types/Modal';
import { TagModal } from 'components/common/Modal/variants/TagModal';
import { KeywordModal } from 'components/common/Modal/variants/KeywordModal';
import { ImageModal } from 'components/common/Modal/variants/ImageModal';
import { StopEditingModal } from 'components/common/Modal/variants/StopEditingModal';
import { SubscribeModal } from 'components/common/Modal/variants/SubscribeModal/SubscribeModal';
import { PaywallModal } from 'components/common/Modal/variants/PaywallModal';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '@chakra-ui/react';
import { VoucherModal } from 'components/common/Modal/variants/VoucherModal';

type Props = {
  children: ReactElement | ReactElement[];
};

export const ModalProvider = ({ children }: Props): ReactElement => {
  const { currentModalValues } = useModalContext();
  const modalService = useModalService();
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  const isModalShown = currentModalValues.isShown;

  const handleClose = (): void => {
    modalService.hide();
  };

  // hide modal when pathname changes (due to paywall)
  useEffect(() => {
    if (pathname !== prevPathname && currentModalValues.isShown) modalService.hide();
  }, [pathname, modalService, currentModalValues, prevPathname]);

  return (
    <>
      <CurrentModal
        currentModalValues={currentModalValues}
        handleClose={handleClose}
        isShown={isModalShown}
        key={
          currentModalValues.variant === ModalVariant.PAYWALL
            ? currentModalValues.target
            : 'default'
        }
      />
      {children}
    </>
  );
};
type CurrentModalProps = {
  currentModalValues: ModalContextValueProps;
  handleClose: () => void;
  isShown: boolean;
};

const CurrentModal = ({
  currentModalValues,
  handleClose,
  isShown,
}: CurrentModalProps): ReactElement | null => {
  switch (currentModalValues.variant) {
    case ModalVariant.LOGOUT:
      return <LogoutModal isShown={isShown} handleClose={handleClose} />;
    case ModalVariant.DISLIKE:
      return (
        <DislikeModal
          isShown={isShown}
          handleClose={handleClose}
          name={currentModalValues.name}
          onAccept={currentModalValues.onAccept}
          variant={currentModalValues.variant}
        />
      );
    case ModalVariant.TAG:
      return (
        <TagModal
          isShown={isShown}
          handleClose={handleClose}
          title={currentModalValues.title}
          tagDescription={currentModalValues.tagDescription}
          variant={currentModalValues.variant}
        />
      );
    case ModalVariant.KEYWORD:
      return (
        <KeywordModal
          isShown={isShown}
          handleClose={handleClose}
          data={currentModalValues.data}
          variant={currentModalValues.variant}
        />
      );
    case ModalVariant.IMAGE:
      return (
        <ImageModal
          isShown={isShown}
          handleClose={handleClose}
          title={currentModalValues.title}
          imgUrl={currentModalValues.imgUrl}
          description={currentModalValues.description}
          variant={currentModalValues.variant}
        />
      );

    case ModalVariant.STOP_EDITING:
      return (
        <StopEditingModal
          isShown={isShown}
          handleClose={handleClose}
          variant={currentModalValues.variant}
        />
      );
    case ModalVariant.SUBSCRIBE:
      return (
        <SubscribeModal
          isShown={isShown}
          handleClose={handleClose}
          variant={currentModalValues.variant}
        />
      );
    case ModalVariant.PAYWALL:
      return (
        <PaywallModal
          isShown={isShown}
          handleClose={handleClose}
          variant={currentModalValues.variant}
          target={currentModalValues.target}
          onUpgradeClick={currentModalValues.onUpgradeClick}
        />
      );
    case ModalVariant.VOUCHER:
      return (
        <VoucherModal
          isShown={isShown}
          handleClose={handleClose}
          variant={currentModalValues.variant}
        />
      );
    default:
      return null;
  }
};
